<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>Choose features for your venue</h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col v-for="vf in venueFeatures" :key="vf.id" cols="12" md="4" lg="3">
                            <v-checkbox
                                v-model="venueFeaturesSelected"
                                :label="vf.name"
                                :value="vf.id"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="save">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import VenueFeature from "@/models/VenueFeature";
import Venue from "@/models/Venue";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "venue-features-sync",
    components: {DashboardLayout},
    data: function () {
        return {
            venueFeatures: [],
            venueFeaturesSelected: [],
        }
    },
    methods: {
        async save() {
            await window.axios.put('/api/venue-features/sync', {
                venue_features: this.$_.map(this.venueFeaturesSelected, vf => vf instanceof VenueFeature ? vf.id : vf)
            })
        },
    },
    async mounted() {
        this.venueFeatures = await VenueFeature.get();

        const venue = await Venue.where('user_id', this.$auth.user().id).first();
        this.venueFeaturesSelected = this.$_.map(await VenueFeature.where('venue', venue.id).get(), vf => vf.id)
    }
}
</script>

<style scoped>

</style>
